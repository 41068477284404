/** @jsx jsx */
import { jsx } from 'theme-ui'

const CloseIcon = ({ open, color }) => {
  return (
    <div
      sx={{
        height: '10px',
        width: '10px',
        padding: '10px',
        transition: '.25s ease',
        borderRadius: '50%',

        '&:active': {
          backgroundColor: 'rgba(0,0,0,.25)'
        }
      }}
    >
      <div
        sx={{
          position: 'relative',
          height: '13px',
          width: '13px',
          '&:before, &:after': {
            content: "''",
            position: 'absolute',
            backgroundColor: color
          },
          '&:before': {
            left: '0',
            top: '-50%',
            height: '100%',
            width: '2px',
            opacity: open ? 0 : 1,
            marginLeft: '-1px',
            transform: open ? 'rotate(90deg)' : 'none',
            transition: '.25s ease'
          },
          '&:after': {
            top: '0',
            left: '-50%',
            width: '100%',
            height: '2px',
            marginTop: '-1px',
            transform: open ? 'rotate(180deg)' : 'none',
            transition: '.25s ease'
          }
        }}
      ></div>
    </div>
  )
}

export default CloseIcon
