/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { Fragment } from 'react'

const ActionButton = ({ text, page, action, external, forwardSx = {} }) => {
  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ['100%', '200px'],
    height: '50px',
    backgroundColor: '#FF5A47',
    borderRadius: '8px',
    border: 'none',
    fontFamily: 'body',
    fontSize: 3,
    fontWeight: 'body',
    color: '#FFDD00',
    cursor: 'pointer',
    textAlign: 'center',
    ...forwardSx
  }

  return (
    <Fragment>
      {action ? (
        <Link to={`/${action}/`} sx={{ ...buttonStyle }}>
          {text}
        </Link>
      ) : text.toLowerCase() === 'chat' ? (
        <button
          onClick={() =>
            typeof window !== 'undefined' && window.GorgiasChat?.open()
          }
          sx={{ ...buttonStyle }}
        >
          {text}
        </button>
      ) : (
        <a href={external} sx={{ ...buttonStyle }}>
          {text}
        </a>
      )}
    </Fragment>
  )
}

export default ActionButton
