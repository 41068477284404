import { graphql } from 'gatsby'
import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentContact from '~/components/Content/Contact'

const Contact = ({ data }) => {
  const { faq, page } = data

  return page ? (
    <Layout>
      <Metadata title={page.name} />
      <ContentContact faq={faq} page={page} />
    </Layout>
  ) : null
}

export default Contact

export const query = graphql`
  query ContactPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "contact" }
      node_locale: { eq: $locale }
    ) {
      name
      ...ContentSectionFragment
    }
    faq: contentfulFaq(
      slug: { eq: "faq-contact-us" }
      node_locale: { eq: $locale }
    ) {
      ...ContentFaqFragment
    }
  }
`
