/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import ActionButton from '~/components/Content/Contact/ActionButton'
import ContentFaqTopic from '~/components/Content/Faq/Topic'

const ContactFAQ = ({ section, faq }) => {
  return (
    <Flex
      sx={{
        color: 'grape',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <div sx={{ mb: '30px', maxWidth: '865px', width: '100%' }}>
        <ContentFaqTopic
          topic={faq.topics[0]}
          forwardSxQuestion={{ marginBottom: 0 }}
          forwardSx={{ fontSize: ['32px', 5], mb: '30px' }}
        />
      </div>
      <ActionButton text={section.title} action="faq" />
    </Flex>
  )
}

export default ContactFAQ
