/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Flex, jsx } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'
import Text from '~/components/IntlComponents/Text'
import ContentHero from '~/components/Content/Hero/index'

const ContactCover = ({ section }) => {
  const translate = useTranslate()
  let userAgent =
    typeof window !== 'undefined' ? window.navigator.userAgent : null
  return (
    <div sx={{ isolation: 'isolate', textAlign: 'center' }}>
      <Heading
        as="h3"
        variant="h3"
        sx={{ color: 'grape', mb: ['30px', '60px'] }}
        dangerouslySetInnerHTML={{
          __html: section.description?.childMarkdownRemark?.html,
        }}
      />
      <Text as="p" sx={{ color: 'grape', mb: ['30px', '60px'] }}>
        {translate('contact.mailing_address')}
      </Text>
      <Flex
        sx={{
          position: 'absolute',
          Zindex: 2,
          transform: 'rotateZ(15deg) translateY(-25%)',
          right: '10vw',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/Stamp.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: ['100px', '200px'],
          width: ['100px', '200px'],
          textAlign: 'center',
          color: '#8821EB',
        }}
      >
        <Text
          sx={{
            fontSize: [1, 3],
            width: ['50%', '100%'],
            lineHeight: '14px',
            color: 'inherit',
          }}
        >
          {section.title}
        </Text>
      </Flex>
      <div sx={{ position: 'relative', zIndex: -1 }}>
        <ContentHero
          hero={section.media[0]}
          sx={{
            maskImage: 'url(/images/wiggle-mask.svg)',
            maskSize: [
              userAgent && userAgent.match(/iPhone/i) ? 'cover' : '100% 100%',
              'contain',
            ],
            '.gatsby-image-wrapper': {
              '& > div': {
                paddingBottom: ['114% !important', '61.111% !important'],
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default ContactCover
