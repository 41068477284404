/** @jsx jsx */
import ActionButton from '~/components/Content/Contact/ActionButton'
import { Grid, Flex, jsx } from 'theme-ui'
import Text from '~/components/IntlComponents/Text'
import ImgLoader from '~/components/Generic/ImgLoader'

const ContactGrid = ({ section }) => {
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <Grid
        sx={{
          flexGrow: 3,
          maxWidth: ['315px', '1150px'],
          columnGap: ['60px', '20%'],
          rowGap: ['60px', '150px'],
          alignContent: 'space-between',
          gridTemplateColumns: ['1fr', 'repeat( auto-fit, minmax(25%, 1fr) )'],
          mb: '97px',
        }}
      >
        {section.media.map(object => (
          <Flex
            key={object.id}
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              img: {
                objectFit: 'contain !important',
              },
            }}
          >
            <ImgLoader
              image={object.media}
              forwardSx={{ height: '100%', width: '100%' }}
            />

            {object.label && (
              <Text
                sx={{
                  textAlign: 'center',
                  mt: [4, 2],
                  fontSize: '1.5rem',
                  fontFamily: 'heading',
                  fontWeight: 'heading',
                  color: 'grape',
                }}
              >
                {object.label}
              </Text>
            )}
            {object.description && (
              <Text
                sx={{
                  maxWidth: '370px',
                  textAlign: 'center',
                  mt: ['0'],
                  fontSize: '11px',
                  fontFamily: 'body',
                  color: 'governorBay',
                  a: {
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    fontFamily: 'heading',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: object.description.childMarkdownRemark.html,
                }}
              />
            )}
            {object.cta.isButton ? (
              <Flex
                sx={{ mt: '30px', width: '100%', justifyContent: 'center' }}
              >
                <ActionButton
                  text={object.cta.text}
                  external={object.cta?.externalLink}
                />
              </Flex>
            ) : (
              <Text
                sx={{
                  maxWidth: '340px',
                  textAlign: 'center',
                  mt: '60px',
                  fontSize: 4,
                  fontFamily: 'body',
                  fontWeight: 'heading',
                  color: 'grape',
                }}
              >
                {object.cta.text}
              </Text>
            )}
          </Flex>
        ))}
      </Grid>
    </Flex>
  )
}

export default ContactGrid
