/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'
import useMatchMedia from '~/hooks/utils/use-match-media'
import { default as wave } from '~/components/Content/Faq/Wave.svg'

const Header = ({ section, forwardSx = {} }) => {
  const iphone = useMatchMedia('(max-width:375px)')
  return (
    <Container
      variant="fullWidth"
      sx={{
        overflow: 'hidden',
        color: 'frenchPass',
        backgroundColor: 'governorBay',
        pt: '100px',
        ...forwardSx,
      }}
    >
      <Container variant="fullWidth" sx={{ textAlign: 'center', px: '30px' }}>
        <Box sx={{ marginBottom: ['40px', '60px'] }}>
          <Heading
            as="h1"
            variant="h2"
            sx={{
              fontSize: ['32px', '60px'],
              fontWeight: '900',
              mb: '30px',
              color: 'inherit',
            }}
          >
            {section.title}
          </Heading>
          <Box
            dangerouslySetInnerHTML={{
              __html: section.description?.childMarkdownRemark.html,
            }}
            sx={{
              maxWidth: '770px',
              '& p': {
                fontFamily: 'body',
                fontSize: ['18px', '20px'],
                fontWeight: 'body',
              },
              margin: '0 auto',
              '& a': {
                color: 'accent',
                textDecoration: 'underline',
                ':hover': { color: 'primary' },
              },
              variant: 'text.subhead',
            }}
          />
        </Box>
      </Container>
      <img
        src={wave}
        alt="wave"
        sx={{
          overflow: 'hidden',
          mb: '-10px',
          padding: 0,
          position: 'relative',
          top: '0px',
          objectFit: 'cover',
          width: iphone ? '650px' : ['768px', '100%'],
          height: ['140px', '20vw'],
          objectPosition: '100% 0%',
          transform: iphone && 'scaleX(-1) translateX(35%)',
        }}
      />
    </Container>
  )
}

export default Header
