/* eslint-disable array-callback-return */
/* eslint-disable default-case */
/** @jsx jsx */
import { Box, jsx, useColorMode } from 'theme-ui'
import { useEffect } from 'react'

import ContactGrid from './Contact'
import Header from '../PageHeader/header'
import ContactFAQ from '~/components/Content/Contact/ContactFAQ'
import ContactCover from '~/components/Content/Contact/ContactCover'
import ContactFooter from '~/components/Content/Contact/ContactFooter'

const ContentContact = ({ page, faq }) => {
  const [, setColorMode] = useColorMode()
  //run on mount
  useEffect(() => {
    setColorMode('Contact-Us')
  }, [])
  return (
    <Box>
      <Header
        section={page.sections[0]}
        forwardSx={{
          color: 'frenchPass',
          backgroundColor: 'grape',

          h1: {
            fontSize: ['32px', 9]
          }
        }}
      />
      <Box sx={{ backgroundColor: 'frenchPass' }}>
        {page.sections &&
          page.sections.map(section => {
            switch (section.slug) {
              case 'contact-us-contact-section':
                return (
                  <div sx={{ px: ['30px', '150px'] }}>
                    <ContactGrid section={section} />
                  </div>
                )
              case 'contact-us-faq-action':
                return (
                  <div sx={{ px: ['30px', '150px'], mb: ['90px', '120px'] }}>
                    <ContactFAQ section={section} faq={faq} />
                  </div>
                )
              case 'contact-us-hero':
                return (
                  <div sx={{ mb: ['50px', '150px'] }}>
                    <ContactCover section={section} />
                  </div>
                )
              case 'contact-us-goodles-logo':
                return <ContactFooter section={section} />
            }
          })}
      </Box>
    </Box>
  )
}

export default ContentContact
